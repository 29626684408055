:root {
    overflow-x: hidden;
    --selected-color: #f7fbfc;
}

* {
    margin: 0;
    padding: 0;
}

html,
body {
    // overflow-x: hidden;
    // width: 100%;
    scroll-behavior: smooth;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.48);
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// date-range overlay animation
.overlayView {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.14);
    z-index: 9999;
    transition: ease-in 0.5s;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    background-color: #e03568 !important;
}

.custom-tooltip-first-col {
    background-color: #fafafa;
    border: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.MuiGrid-item {
    scrollbar-width: thin;
    scrollbar-color: #ebf6f9 transparent;
}
