.pageLoader {
  height: 3px;
  width: 100%;
  position: fixed;
  overflow: hidden;
  background-color: #ddd;
  top:0;
  left:0;
}

.pageLoader::before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #1eac70;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% { left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
}
